import { Toast } from '@sobrus-com/sobrus-design-system-v2';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../hooks';

import Header from './Header';

export function ProtectedHeader() {
    const location = useLocation();
    const { loading, error } = useAppSelector((state) => state.user);

    if (location.pathname === '/' || location.pathname === '/not_found' || location.pathname === '/error') {
        return null;
    }
    if (loading) return null;
    if (error) return null;

    return (
        <>
            <Toast closeOnClick pauseOnHover hideProgressBar={false} newestOnTop={false} position="top-right" />
            <Header />
        </>
    );
}
