let apiUrl = 'https://api.ecopara.ovh/api/admin/';
let PUBLIC_BASE_URL = 'https://api.ecopara.ovh/api';
let BASE_VESUALASE_URL = '.ecopara.ovh';
let MANAGE_PROFILE_URL = 'https://account.sobrus.ovh/account';
let BASE_URL_IMAGE_ECOPHARMAE_POST = 'https://api.ecopara.ovh/images/posts/';
let TRANSPORTS_BASE_URL_IMAGE = 'https://api.ecopara.ovh/images/carriers/';
let BASE_URL_IMAGE_CENTRAL = 'https://ma.pharma.sobrus.ovh/uploads/ma/products/ecopara/watermarked/';
let imgsBaseUrl = 'https://api.ecopara.ovh/images/products/';
let TOKEN_URL =
  'https://api.account.sobrus.ovh/authorize?response_type=code&client_id=10014&scope=openid%20offline_access%20profile%20email&redirect_uri=https://api.ecopara.ovh/auth/token&prompt=login';
let LOGO_BASE_URL = 'https://api.ecopara.ovh/images/logos/';
let FAVICON_BASE_URL = 'https://api.ecopara.ovh/images/favicons/';
let CATEGORIESE_BASE_URL = 'https://api.ecopara.ovh/images/categories/';
let GET_CITIES = 'https://api.ecopara.ovh/api/cities';
let GET_COUNTRIES = 'https://api.ecopara.ovh/api/countries';
let pdfLink = 'https://api.pdfs.sobrus.com/ecopara';


if (import.meta.env.VITE_REACT_APP_ENV === 'production') {
  pdfLink = 'https://api.pdfs.sobrus.com/ecopara';
  BASE_VESUALASE_URL = '.ecopara.store';
  BASE_URL_IMAGE_CENTRAL = 'https://ma.pharma.sobrus.com/uploads/ma/products/ecopara/watermarked/';
  CATEGORIESE_BASE_URL = 'https://api.ecopara.store/images/categories/';
  FAVICON_BASE_URL = 'https://api.ecopara.store/images/favicons/';
  GET_CITIES = 'https://api.ecopara.store/api/cities';
  apiUrl = 'https://api.ecopara.store/api/admin/';
  LOGO_BASE_URL = 'https://api.ecopara.store/images/logos/';
  PUBLIC_BASE_URL = 'https://api.ecopara.store/api';
  MANAGE_PROFILE_URL = 'https://account.sobrus.com/account';
  imgsBaseUrl = 'https://api.ecopara.store/images/products/';
  BASE_URL_IMAGE_ECOPHARMAE_POST = 'https://api.ecopara.store/images/posts/';
  TRANSPORTS_BASE_URL_IMAGE = 'https://api.ecopara.store/images/carriers/';
  GET_COUNTRIES = 'https://api.ecopara.store/api/countries';
  TOKEN_URL =
    'https://api.account.sobrus.com/authorize?response_type=code&client_id=10014&scope=openid%20offline_access%20profile%20email&redirect_uri=https://api.ecopara.store/auth/token&prompt=login';
}

export {
  TRANSPORTS_BASE_URL_IMAGE,
  apiUrl,
  MANAGE_PROFILE_URL,
  TOKEN_URL,
  imgsBaseUrl,
  BASE_URL_IMAGE_ECOPHARMAE_POST,
  PUBLIC_BASE_URL,
  LOGO_BASE_URL,
  FAVICON_BASE_URL,
  CATEGORIESE_BASE_URL,
  BASE_URL_IMAGE_CENTRAL,
  GET_CITIES,
  BASE_VESUALASE_URL,
  pdfLink,
  GET_COUNTRIES
};
