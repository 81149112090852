import { Navigate } from 'react-router-dom';
import { LazyExoticComponent, Suspense } from 'react';
import { useAppSelector, useAcl } from '../hooks';
import { LoadingPage } from '@/Components';

type PotectedRouteTypes = {
    acl: string[];
    element: LazyExoticComponent<() => JSX.Element>;
};

function ProtectedRoute({ acl, element: Component }: PotectedRouteTypes) {
    const { loading, error } = useAppSelector((state) => state.user);
    const { canAll } = useAcl();
    if (loading === false) {
        if (error) {
            return <Navigate to={{ pathname: '/error' }} />;
        }
        if (canAll(acl)) {
            return (
                <Suspense fallback={<LoadingPage />}>
                    <Component />
                </Suspense>
            );
        }

        return <Navigate to={{ pathname: '/denied_access' }} />;
    }

    return <LoadingPage />;
}

export default ProtectedRoute;
