import { apiUrl } from '@/env';
import { setAppParams, setAppParamsError } from '@/redux/features/appParamsSlice';
import axios, { isAxiosError } from 'axios';
import { useCallback } from 'react'
import { useAppDispatch } from '.';

export const useAppParams = () => {
  const dispatch = useAppDispatch();
  const getAppParams = useCallback(async () => {
    try {
      const { data } = await axios.get(apiUrl + `parameters/application-params`, {
        withCredentials: true,
        responseType: 'json',
      });

      dispatch(setAppParams(data));
    } catch (err) {
      if (isAxiosError(err) && err.response) {
        dispatch(setAppParamsError(err?.response?.data?.message));
      }
    }
  }, [dispatch]);

  return { getAppParams };
}
