import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { visualParamsType } from '../../types';

export interface visualParamsState {
    visualParams: visualParamsType;
    loading: boolean;
    error: string;
}

const initialState: Readonly<visualParamsState> = {
    visualParams: {} as visualParamsType,
    loading: true,
    error: '',
};

const visualParams = createSlice({
    name: 'visualParams',
    initialState,
    reducers: {
        setVisualParams: (state, action: PayloadAction<visualParamsType>) => {
            state.visualParams = action.payload;
            state.loading = false;
        },
        setVisualParamsError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export const { setVisualParams, setVisualParamsError } = visualParams.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default visualParams.reducer;
