import { ACL } from './acl';

export const navPathData = [
	{
		to: '/admin/home',
		itemTitle: 'Tableau de bord',
		does: ACL.HOME.GET_ALL,
	},
	{
		to: '/admin/clients',
		itemTitle: 'Clients',
		does: ACL.CLIENTS.GET_ALL,
	},
	{
		to: '/admin/carts',
		itemTitle: 'Paniers',
		does: ACL.CART.GET_ALL,
	},
	{
		to: '/admin/orders',
		itemTitle: 'Commandes',
		does: ACL.ORDER.GET_ALL,
	},
	{
		to: '/admin/ads',
		itemTitle: 'Publicités',
		does: ACL.ORDER.GET_ALL,
		subItems: [
			{
				to: '/admin/flash-infos',
				itemTitle: 'Flash info',
				does: ACL.ORDER.GET_ALL,
			},
		],
	},
	{
		to: '/admin/categories',
		itemTitle: 'Catégories',
		does: ACL.CATEGORIES.GET_ALL_CATEGORIES,
		subItems: [
			{
				to: '/admin/sub-categories',
				itemTitle: 'Sous-catégories',
				does: ACL.CATEGORIES.GET_ALL_SUB_CATEGORIES,
			},
			{
				to: '/admin/sub-sub-categories',
				itemTitle: 'Sous-sous-catégories',
				does: ACL.CATEGORIES.GET_ALL_SUB_SUB_CATEGORIES,
			},
		],
	},
	{
		to: '/admin/products',
		itemTitle: 'Produits',
		does: ACL.PRODUCTS.GET_ALL,
		subItems: [
			{
				to: '/admin/products',
				itemTitle: 'Produits',
				does: ACL.PRODUCTS.GET_ALL,
			},
			{
				to: '/admin/import-products',
				itemTitle: 'Mapping des produits',
				does: ACL.PRODUCTS.GET_ALL,
			},
		],
	},
	{
		to: '/admin/promo_codes',
		itemTitle: 'Codes promo',
		does: ACL.PROMOCODE.GET_ALL,
	},
	{
		to: '/admin/reviews',
		itemTitle: 'Avis clients',
		does: ACL.CLIENT_REVIEW.GET_ALL,
	},
	{
		to: '/admin/report',
		itemTitle: 'Rapports',
		does: ACL.REPORTS.ORDERS,
	},
	{
		to: '/admin/wallet',
		itemTitle: 'Wallet',
		does: ACL.WALLET.GET_ALL,
	},
	{
		to: '/admin/blog-articles',
		itemTitle: 'Articles',
		does: ACL.BLOG_ARTICLES.GET_ALL,
	},
	{
		to: ['admin/shop_data'],
		itemTitle: 'Paramètres',
		does: ACL.PARAMS.APP_PARAMS_UPDATE,
	},
];
