import { apiUrl } from '@/env';
import { setPaymentParams, setPaymentParamsError } from '@/redux/features/paymentParamSlice';
import axios, { isAxiosError } from 'axios';
import { useCallback } from 'react'
import { useAppDispatch } from '.';

export const usePaymentParams = () => {
  const dispatch = useAppDispatch();
  const getPaymentParams = useCallback(async () => {
    try {
      const { data } = await axios.get(apiUrl + `parameters/payment-params`, {
        withCredentials: true,
        responseType: 'json',
      });

      dispatch(setPaymentParams(data));
    } catch (err) {
      if (isAxiosError(err) && err.response) {
        dispatch(setPaymentParamsError(err?.response?.data?.message));
      }
    }
  }, [dispatch]);

  return { getPaymentParams };
}
