import { SpinnerLoading } from '@sobrus-com/sobrus-design-system-v2';
import ContentLoader from 'react-content-loader';

export function LoadingPage() {
    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <SpinnerLoading loading color="#3F8C7D" title="Loading" />
        </div>
    );
}

export function ItemsLoader() {
    return (
        <ContentLoader
            backgroundColor="#3f8c7d2a"
            foregroundColor="rgb(24, 177, 212,0.3)"
            height={16}
            speed={3}
            viewBox="0 0 100 16"
            width={100}
        >
            <rect height="16" rx="3" ry="3" width="100" x="0" y="0" />
        </ContentLoader>
    );
}
