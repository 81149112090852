import { AppPaymentsParamsType } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PaymentParamsState {
  paymentMode?: AppPaymentsParamsType;
  loading: boolean;
  error: string;
}

const initialState: Readonly<PaymentParamsState> = {
  paymentMode: {} as AppPaymentsParamsType,
  loading: true,
  error: '',
};

const paymentParams = createSlice({
    name: 'paymentParams',
    initialState,
    reducers: {
        setPaymentParams: (state, action: PayloadAction<AppPaymentsParamsType>) => {
            state.paymentMode = action.payload;
            state.loading = false;
        },
        setPaymentParamsError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export const { setPaymentParams, setPaymentParamsError } = paymentParams.actions;


export default paymentParams.reducer;

