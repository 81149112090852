import { lazy } from 'react';
import { ACL } from './acl';

/** DashBoard */
const DashBoard = lazy(
	() =>
		import(/* webpackChunkName: "DashBoard" */ '@/pages/DashBoard/DashBoard')
);
const ClientsTable = lazy(
	() =>
		import(
			/* webpackChunkName: "ClientsTable" */ '@/pages/ClientsTable/ClientsTable'
		)
);
const ClientDetails = lazy(
	() =>
		import(
			/* webpackChunkName: "ClientDetails" */ '@/pages/ClientDetails/ClientDetails'
		)
);
const CartsTable = lazy(
	() =>
		import(/* webpackChunkName: "CartsTable" */ '@/pages/CartsTable/CartsTable')
);
const CartDetails = lazy(
	() =>
		import(
			/* webpackChunkName: "CartDetails" */ '@/pages/CartDetails/CartDetails'
		)
);
const OrdersTable = lazy(
	() =>
		import(
			/* webpackChunkName: "OrdersTable" */ '@/pages/OrdersTable/OrdersTable'
		)
);
const OrderDetails = lazy(
	() =>
		import(
			/* webpackChunkName: "OrderDetails" */ '@/pages/OrderDetails/OrderDetails'
		)
);
const AdsTable = lazy(
	() => import(/* webpackChunkName: "AdsTable" */ '@/pages/AdsTable/AdsTable')
);
const AdsDetails = lazy(
	() =>
		import(/* webpackChunkName: "AdsDetails" */ '@/pages/AdsDetails/AdsDetails')
);
const AdsAddOrUpdate = lazy(
	() =>
		import(
			/* webpackChunkName: "AdsAddOrUpdate" */ '@/pages/AdsAddOrUpdate/AdsAddOrUpdate'
		)
);
const FlashInfosTable = lazy(
	() =>
		import(
			/* webpackChunkName: "FlashInfosTable" */ '@/pages/FlashInfosTable/FlashInfosTable'
		)
);
const FlashInfosAddOrUpdate = lazy(
	() =>
		import(
			/* webpackChunkName: "FlashInfosAddOrUpdate" */ '@/pages/FlashInfosAddOrUpdate/FlashInfosAddOrUpdate'
		)
);
const CategoriesTable = lazy(
	() =>
		import(
			/* webpackChunkName: "CategoriesTable" */ '@/pages/CategoriesTable/CategoriesTable'
		)
);
const SubCategoriesTable = lazy(
	() =>
		import(
			/* webpackChunkName: "SubCategoriesTable" */ '@/pages/SubCategoriesTable/SubCategoriesTable'
		)
);
const SubSubCategoriesTable = lazy(
	() =>
		import(
			/* webpackChunkName: "SubSubCategoriesTable" */ '@/pages/SubSubCategoriesTable/SubSubCategoriesTable'
		)
);
const ProductsTable = lazy(
	() =>
		import(
			/* webpackChunkName: "ProductsTable" */ '@/pages/ProductsTable/ProductsTable'
		)
);
const ProductsDetails = lazy(
	() =>
		import(
			/* webpackChunkName: "ProductsDetails" */ '@/pages/ProductsDetails/ProductsDetails'
		)
);
const ProductsUpdate = lazy(
	() =>
		import(
			/* webpackChunkName: "ProductsUpdate" */ '@/pages/ProductsUpdate/ProductsUpdate'
		)
);
const ProductsSynchronizeTable = lazy(
	() =>
		import(
			/* webpackChunkName: "ProductsSynchronizeTable" */ '@/pages/ProductsTable/ProductsSynchronizeTable'
		)
);

const PromoCodesTable = lazy(
	() =>
		import(
			/* webpackChunkName: "PromoCodesTable" */ '@/pages/PromoCode/PromoCodesTable/PromoCodesTable'
		)
);
const PromoCodesDetails = lazy(
	() =>
		import(
			/* webpackChunkName: "PromoCodesDetails" */ '@/pages/PromoCode/PromoCodesDetails/PromoCodesDetails'
		)
);
const PromoCodesAddOrUpdate = lazy(
	() =>
		import(
			/* webpackChunkName: "PromoCodesAddOrUpdate" */ '@/pages/PromoCode/PromoCodesAddOrUpdate/PromoCodesAddOrUpdate'
		)
);

const ClientsReviewsTable = lazy(
	() =>
		import(
			/* webpackChunkName: "ClientsReviewsTable" */ '@/pages/ClientReview/ClientsReviewsTable/ClientsReviewsTable'
		)
);
const ClientsReviewsDetails = lazy(
	() =>
		import(
			/* webpackChunkName: "ClientsReviewsDetails" */ '@/pages/ClientReview/ClientsReviewsDetails/ClientsReviewsDetails'
		)
);

const ReportsPage = lazy(
	() =>
		import(
			/* webpackChunkName: "ReportsPage" */ '@/pages/Reports/ReportsPage/ReportsPage'
		)
);
const ReportsTransporters = lazy(
	() =>
		import(
			/* webpackChunkName: "ReportsTransporters" */ '@/pages/Reports/ReportsTransporters/ReportsTransporters'
		)
);
const ReportsTurnOver = lazy(
	() =>
		import(
			/* webpackChunkName: "ReportsTurnOver" */ '@/pages/Reports/ReportsTurnOver/ReportsTurnOver'
		)
);
const ReportsOrders = lazy(
	() =>
		import(
			/* webpackChunkName: "ReportsOrders" */ '@/pages/Reports/ReportsOrders/ReportsOrders'
		)
);

const WalletsPage = lazy(
	() =>
		import(
			/* webpackChunkName: "WalletsPage" */ '@/pages/Wallets/WalletsPage/WalletsPage'
		)
);
const WalletsDetails = lazy(
	() =>
		import(
			/* webpackChunkName: "WalletsDetails" */ '@/pages/Wallets/WalletsDetails/WalletsDetails'
		)
);

const BlogArticlesTable = lazy(
	() =>
		import(
			/* webpackChunkName: "BlogArticlesTable" */ '@/pages/BlogArticles/BlogArticlesTable/BlogArticlesTable'
		)
);
const BlogArticlesDetails = lazy(
	() =>
		import(
			/* webpackChunkName: "BlogArticlesDetails" */ '@/pages/BlogArticles/BlogArticlesDetails/BlogArticlesDetails'
		)
);
const BlogArticlesAddOrUpdate = lazy(
	() =>
		import(
			/* webpackChunkName: "BlogArticlesAddOrUpdate" */ '@/pages/BlogArticles/BlogArticlesAddOrUpdate/BlogArticlesAddOrUpdate'
		)
);

const ShpeMode = lazy(
	() =>
		import(
			/* webpackChunkName: "ShpeMode" */ '@/pages/Params/ShpeMode/ShpeMode'
		)
);
const ShopData = lazy(
	() =>
		import(
			/* webpackChunkName: "ShopData" */ '@/pages/Params/ShopData/ShopData'
		)
);
const VisualIdentity = lazy(
	() =>
		import(
			/* webpackChunkName: "VisualIdentity" */ '@/pages/Params/VisualIdentity/VisualIdentity'
		)
);
const VisualAdvantages = lazy(
	() =>
		import(
			/* webpackChunkName: "VisualAdvantages" */ '@/pages/Params/VisualAdvantages/VisualAdvantages'
		)
);
const CookiesParams = lazy(
	() =>
		import(
			/* webpackChunkName: "CookiesParams" */ '@/pages/Params/CookiesParams/CookiesParams'
		)
);
const OpeningHoursParam = lazy(
	() =>
		import(
			/* webpackChunkName: "OpeningHoursParam" */ '@/pages/Params/OpeningHoursParam/OpeningHoursParam'
		)
);
const LegalNoticeParam = lazy(
	() =>
		import(
			/* webpackChunkName: "LegalNoticeParam" */ '@/pages/Params/LegalNoticeParam/LegalNoticeParam'
		)
);
const TermsAndConditionsParam = lazy(
	() =>
		import(
			/* webpackChunkName: "TermsAndConditionsParam" */ '@/pages/Params/TermsAndConditionsParam/TermsAndConditionsParam'
		)
);
const ShippingPolicyParam = lazy(
	() =>
		import(
			/* webpackChunkName: "ShippingPolicyParam" */ '@/pages/Params/ShippingPolicyParam/ShippingPolicyParam'
		)
);
const ReturnAndRefundPolicyParam = lazy(
	() =>
		import(
			/* webpackChunkName: "ReturnAndRefundPolicyParam" */ '@/pages/Params/ReturnAndRefundPolicyParam/ReturnAndRefundPolicyParam'
		)
);
const SocialMediaParam = lazy(
	() =>
		import(
			/* webpackChunkName: "SocialMediaParam" */ '@/pages/Params/SocialMediaParam/SocialMediaParam'
		)
);
const ProductLineParam = lazy(
	() =>
		import(
			/* webpackChunkName: "ProductLineParam" */ '@/pages/Params/ProductLineParam/ProductLineParam'
		)
);
const TrackingToolParam = lazy(
	() =>
		import(
			/* webpackChunkName: "TrackingToolParam" */ '@/pages/Params/TrackingToolParam/TrackingToolParam'
		)
);
const EmailTemplateList = lazy(
	() =>
		import(
			/* webpackChunkName: "EmailTemplateList" */ '@/pages/Params/EmailTemplate/EmailTemplateList/EmailTemplateList'
		)
);
const EmailTemplateDetails = lazy(
	() =>
		import(
			/* webpackChunkName: "EmailTemplateDetails" */ '@/pages/Params/EmailTemplate/EmailTemplateDetails/EmailTemplateDetails'
		)
);
const DefaultShippingCosts = lazy(
	() =>
		import(
			/* webpackChunkName: "DefaultShippingCosts" */ '@/pages/Params/DefaultShippingCosts/DefaultShippingCosts'
		)
);
const ShippingText = lazy(
	() =>
		import(
			/* webpackChunkName: "ShippingText" */ '@/pages/Params/ShippingText/ShippingText'
		)
);
const ShippingCostsList = lazy(
	() =>
		import(
			/* webpackChunkName: "ShippingCostsList" */ '@/pages/Params/ShippingCostsList/ShippingCostsList'
		)
);
const ShippingCountries = lazy(
	() =>
		import(
			/* webpackChunkName: "ShippingCountries" */ '@/pages/Params/ShippingCountries/ShippingCountries'
		)
);
// const paymentMode = lazy(() => import(/* webpackChunkName: "paymentMode" */ '@/pages/Params/paymentMode/paymentMode'));
const paymentModeToRefactor = lazy(
	() =>
		import(
			/* webpackChunkName: "paymentMode" */ '@/pages/Params/paymentMode/paymentModeToRefactor'
		)
);

const paymentModeText = lazy(
	() =>
		import(
			/* webpackChunkName: "paymentModeText" */ '@/pages/Params/paymentModeText/paymentModeText'
		)
);
const paymentCurrency = lazy(
	() =>
		import(
			/* webpackChunkName: "paymentCurrency" */ '@/pages/Params/paymentCurrency/paymentCurrency'
		)
);
const StockParams = lazy(
	() =>
		import(
			/* webpackChunkName: "StockParams" */ '@/pages/Params/StockParams/StockParams'
		)
);
const ProductSettingParams = lazy(
	() =>
		import(
			/* webpackChunkName: "ProductSettingParams" */ '@/pages/Params/ProductSettingParams/ProductSettingParams'
		)
);
const CarriersParams = lazy(
	() =>
		import(
			/* webpackChunkName: "CarriersParams" */ '@/pages/Params/CarriersParams/CarriersParams'
		)
);
const AclUsersList = lazy(
	() =>
		import(
			/* webpackChunkName: "AclUsersList" */ '@/pages/Params/AclUsersList/AclUsersList'
		)
);
const AclRoles = lazy(
	() =>
		import(
			/* webpackChunkName: "AclRoles" */ '@/pages/Params/AclRoles/AclRoles'
		)
);
const AclRolesDetails = lazy(
	() =>
		import(
			/* webpackChunkName: "AclRolesDetails" */ '@/pages/Params/AclRoles/AclRolesDetails/AclRolesDetails'
		)
);

const notificationsPage = lazy(
	() =>
		import(
			/* webpackChunkName: "notificationsPage" */ '@/pages/notifications/notificationsPage/notificationsPage'
		)
);
const notificationsHistoryPage = lazy(
	() =>
		import(
			/* webpackChunkName: "notificationsHistoryPage" */ '@/pages/notifications/notificationsHistoryPage/notificationsHistoryPage'
		)
);
const ImportList = lazy(
	() =>
		import(
			/* webpackChunkName: "ImportList" */ '@/pages/ImportProducts/ImportList'
		)
);

const ImportProducts = lazy(
	() =>
		import(
			/* webpackChunkName: "ImportProducts" */ '@/pages/ImportProducts/ImportProducts'
		)
);
const UnmappedRows = lazy(
	() =>
		import(
			/* webpackChunkName: "UnmappedRows" */ '@/pages/ImportProducts/UnmappedRows'
		)
);

export const APP_ROUTES = [
	/** DashBoard */
	{ component: DashBoard, acl: ACL.HOME.GET_ALL, path: '/admin/home' },

	/** CLIENTS */
	{ component: ClientsTable, acl: ACL.CLIENTS.GET_ALL, path: '/admin/clients' },
	{
		component: ClientDetails,
		acl: ACL.CLIENTS.GET_BY_ID,
		path: '/admin/clients/view/:id',
	},

	/** CARDS */
	{ component: CartsTable, acl: ACL.CART.GET_ALL, path: '/admin/carts' },
	{
		component: CartDetails,
		acl: ACL.CLIENTS.GET_BY_ID,
		path: '/admin/carts/view/:id',
	},

	/** ORDERS */
	{ component: OrdersTable, acl: ACL.ORDER.GET_ALL, path: '/admin/orders' },
	{
		component: OrderDetails,
		acl: ACL.ORDER.GET_BY_ID,
		path: '/admin/orders/view/:id',
	},

	/** ADS */
	{ component: AdsTable, acl: ACL.ADS.GET_ALL, path: '/admin/ads' },
	{
		component: AdsDetails,
		acl: ACL.ADS.GET_BY_ID,
		path: '/admin/ads/view/:id',
	},
	{
		component: AdsAddOrUpdate,
		acl: ACL.ADS.CREATE,
		path: '/admin/ads/add_or_update',
	},
	{
		component: AdsAddOrUpdate,
		acl: ACL.ADS.UPDATE,
		path: '/admin/ads/add_or_update/:id',
	},

	/** FLASHINFOS */
	{
		component: FlashInfosTable,
		acl: ACL.FLASHINFO.GET_ALL,
		path: '/admin/flash-infos',
	},
	{
		component: FlashInfosAddOrUpdate,
		acl: ACL.FLASHINFO.UPDATE,
		path: '/admin/flash-infos/addOrUpdate/:id',
	},
	{
		component: FlashInfosAddOrUpdate,
		acl: ACL.FLASHINFO.CREATE,
		path: '/admin/flash-infos/addOrUpdate',
	},

	/** CATEGORIES */
	{
		component: CategoriesTable,
		acl: ACL.CATEGORIES.GET_ALL_CATEGORIES,
		path: '/admin/categories',
	},
	{
		component: SubCategoriesTable,
		acl: ACL.CATEGORIES.GET_ALL_SUB_CATEGORIES,
		path: '/admin/sub-categories',
	},
	{
		component: SubSubCategoriesTable,
		acl: ACL.CATEGORIES.GET_ALL_SUB_SUB_CATEGORIES,
		path: '/admin/sub-sub-categories',
	},

	/** PRODUCTS */
	{
		component: ProductsTable,
		acl: ACL.PRODUCTS.GET_ALL,
		path: '/admin/products',
	},
	{
		component: ProductsSynchronizeTable,
		acl: ACL.PRODUCTS.GLOVO_LIST,
		path: '/admin/products/synchronize-glovo',
	},
	{
		component: ProductsDetails,
		acl: ACL.PRODUCTS.GET_BY_ID,
		path: '/admin/products/view/:id',
	},
	{
		component: ProductsUpdate,
		acl: ACL.PRODUCTS.GET_BY_ID,
		path: '/admin/products/edit/:id',
	},

	/** PRODUCTS MAPPING */
	{
		component: ImportList,
		acl: ACL.PRODUCTS.GET_ALL,
		path: '/admin/import-products',
	},
	{
		component: ImportProducts,
		acl: ACL.PRODUCTS.GET_ALL,
		path: '/admin/import-products/steps',
	},
	{
		component: UnmappedRows,
		acl: ACL.PRODUCTS.GET_ALL,
		path: '/admin/import-products/unmapped-rows/:id',
	},
	// {
	// 	component: ProductsSynchronizeTable,
	// 	acl: ACL.PRODUCTS.GLOVO_LIST,
	// 	path: '/admin/products/synchronize-glovo',
	// },
	// {
	// 	component: ProductsDetails,
	// 	acl: ACL.PRODUCTS.GET_BY_ID,
	// 	path: '/admin/products/view/:id',
	// },
	// {
	// 	component: ProductsUpdate,
	// 	acl: ACL.PRODUCTS.GET_BY_ID,
	// 	path: '/admin/products/edit/:id',
	// },

	/** PROMOCODE */
	{
		component: PromoCodesTable,
		acl: ACL.PROMOCODE.GET_ALL,
		path: '/admin/promo_codes',
	},
	{
		component: PromoCodesDetails,
		acl: ACL.PROMOCODE.GET_BY_ID,
		path: '/admin/promo_codes/view/:id',
	},
	{
		component: PromoCodesAddOrUpdate,
		acl: ACL.PROMOCODE.UPDATE,
		path: '/admin/promo_codes/edit/:id',
	},
	{
		component: PromoCodesAddOrUpdate,
		acl: ACL.PROMOCODE.CREATE,
		path: '/admin/promo_codes/create',
	},

	/** REVIEWS */
	{
		component: ClientsReviewsTable,
		acl: ACL.CLIENT_REVIEW.GET_ALL,
		path: '/admin/reviews',
	},
	{
		component: ClientsReviewsDetails,
		acl: ACL.CLIENT_REVIEW.GET_BY_ID,
		path: '/admin/reviews/view/:id',
	},

	/** REPORT */
	{ component: ReportsPage, acl: ACL.REPORTS.ORDERS, path: '/admin/report' },
	{
		component: ReportsTransporters,
		acl: ACL.REPORTS.CARRIERS,
		path: '/admin/report/transporters',
	},
	{
		component: ReportsTurnOver,
		acl: ACL.REPORTS.TURNOVER,
		path: '/admin/report/turnover',
	},
	{
		component: ReportsOrders,
		acl: ACL.REPORTS.TURNOVER,
		path: '/admin/report/orders',
	},

	/** WALLET */
	{ component: WalletsPage, acl: ACL.WALLET.GET_ALL, path: '/admin/wallet' },
	{
		component: WalletsDetails,
		acl: ACL.WALLET.GET_BY_ID,
		path: '/admin/wallet/view/:id',
	},

	/** BLOG_ARTICLES */
	{
		component: BlogArticlesTable,
		acl: ACL.BLOG_ARTICLES.GET_ALL,
		path: '/admin/blog-articles',
	},
	{
		component: BlogArticlesDetails,
		acl: ACL.BLOG_ARTICLES.GET_BY_ID,
		path: '/admin/blog-articles/view/:id',
	},
	{
		component: BlogArticlesAddOrUpdate,
		acl: ACL.BLOG_ARTICLES.UPDATE,
		path: '/admin/blog-articles/edit/:id',
	},
	{
		component: BlogArticlesAddOrUpdate,
		acl: ACL.BLOG_ARTICLES.CREATE,
		path: '/admin/blog-articles/create',
	},

	/** NOTIFICATIONS */
	{
		component: notificationsPage,
		acl: ACL.NOTIFICATIONS.GET_ALL,
		path: '/admin/notifications',
	},
	{
		component: notificationsHistoryPage,
		acl: ACL.NOTIFICATIONS.GET_ALL_HISTORY,
		path: '/admin/notifications/history',
	},

	/** PARAMS */
	{
		component: ShpeMode,
		acl: ACL.PARAMS.APP_PARAMS_UPDATE,
		path: '/admin/shop/mode',
	},
	{
		component: ShopData,
		acl: ACL.PARAMS.APP_PARAMS_UPDATE,
		path: '/admin/shop_data',
	},
	{
		component: VisualIdentity,
		acl: ACL.PARAMS.APP_VISUAL_PARAMS_GET,
		path: '/admin/visual_identity',
	},
	{
		component: VisualAdvantages,
		acl: ACL.PARAMS.ADVANTEGES.GET_ALL,
		path: '/admin/advantages',
	},
	{
		component: CookiesParams,
		acl: ACL.PARAMS.APP_PARAMS_UPDATE,
		path: '/admin/cookies',
	},
	{
		component: OpeningHoursParam,
		acl: ACL.PARAMS.APP_PARAMS_UPDATE,
		path: '/admin/opening-hours',
	},
	{
		component: LegalNoticeParam,
		acl: ACL.PARAMS.APP_VISUAL_PARAMS_UPDATE,
		path: '/admin/legal_notice',
	},
	{
		component: TermsAndConditionsParam,
		acl: ACL.PARAMS.APP_VISUAL_PARAMS_UPDATE,
		path: '/admin/terms_and_conditions',
	},
	{
		component: ShippingPolicyParam,
		acl: ACL.PARAMS.APP_VISUAL_PARAMS_UPDATE,
		path: '/admin/shipping_policy',
	},
	{
		component: ReturnAndRefundPolicyParam,
		acl: ACL.PARAMS.APP_VISUAL_PARAMS_UPDATE,
		path: '/admin/return_and_refund_policy',
	},
	{
		component: SocialMediaParam,
		acl: ACL.PARAMS.APP_VISUAL_PARAMS_UPDATE,
		path: '/admin/social_media',
	},
	{
		component: ProductLineParam,
		acl: ACL.PARAMS.PRODUCT_LINE.GET_ALL,
		path: '/admin/marketing/product-line',
	},
	{
		component: TrackingToolParam,
		acl: ACL.PARAMS.APP_PARAMS_UPDATE,
		path: '/admin/tracking-tool',
	},

	{
		component: EmailTemplateList,
		acl: ACL.PARAMS.EMAIL_TEMPLATE.GET_ALL,
		path: '/admin/emails-templates/list',
	},
	{
		component: EmailTemplateDetails,
		acl: ACL.PARAMS.EMAIL_TEMPLATE.UPDATE,
		path: '/admin/emails-templates/:id',
	},

	{
		component: ShippingCountries,
		acl: ACL.PARAMS.APP_PARAMS_UPDATE,
		path: '/admin/shipping/shipping_countries',
	},
	{
		component: DefaultShippingCosts,
		acl: ACL.PARAMS.APP_PARAMS_UPDATE,
		path: '/admin/shipping/default_shipping_costs',
	},
	{
		component: ShippingText,
		acl: ACL.PARAMS.APP_VISUAL_PARAMS_UPDATE,
		path: '/admin/shipping/text',
	},
	{
		component: ShippingCostsList,
		acl: ACL.PARAMS.SHIPPING_COST.GET_ALL,
		path: '/admin/shipping/shipping_costs',
	},

	{
		component: paymentModeToRefactor,
		acl: ACL.PARAMS.APP_PAYEMENT_PARAMS_UPDATE,
		path: '/admin/payment/details',
	},
	{
		component: paymentModeText,
		acl: ACL.PARAMS.APP_VISUAL_PARAMS_UPDATE,
		path: '/admin/payment/text',
	},
	{
		component: paymentCurrency,
		acl: ACL.PARAMS.APP_VISUAL_PARAMS_UPDATE,
		path: '/admin/payment/currency',
	},

	{
		component: StockParams,
		acl: ACL.PARAMS.APP_PARAMS_UPDATE,
		path: '/admin/stock/details',
	},
	{
		component: ProductSettingParams,
		acl: ACL.PARAMS.ACTIVETE_PHARMA_STOCK,
		path: '/admin/stock/products-setting',
	},

	{
		component: CarriersParams,
		acl: ACL.PARAMS.CARRIERS.GET_ALL,
		path: '/admin/transporters',
	},
	{
		component: AclUsersList,
		acl: ACL.PARAMS.ACLS_USERS.GET_ALL,
		path: '/settings/acl/users',
	},
	{
		component: AclRoles,
		acl: ACL.PARAMS.ACLS_ROLES.GET_ALL,
		path: '/settings/acl/roles',
	},
	{
		component: AclRolesDetails,
		acl: ACL.PARAMS.ACLS_ROLES.GET_BY_ID,
		path: '/settings/acl/roles/:id',
	},
];
