import { notify } from '@sobrus-com/sobrus-design-system-v2';
import { isAxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from '../../services';
// import { catchFunction } from '@/helpers';
import { UseAddUpdateState, UseUpdateState } from './type';

export const useAddUpdate = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	async function Add<T>({
		url,
		formData,
		redirectUrl,
		redirecToView,
		message,
		cb,
	}: UseAddUpdateState<T>) {
		try {
			setLoading(true);
			const { data: addData } = await API.post(`${url}`, formData);
			cb?.(addData as T);
			(redirectUrl || redirecToView) &&
				navigate(redirectUrl ?? `${redirecToView}/${addData?.id}`, {
					state: { detail: 'success', message },
				});
		} catch (error) {
			if (isAxiosError(error)) {
				// catchFunction(error);
			} else {
				notify({
					type: 'danger',
					msg: `Une erreur est survenue, veuillez réessayer`,
					delay: 5000,
				});
			}
		} finally {
			setLoading(false);
		}
	}
	async function Update<T>({
		url,
		formData,
		redirectUrl,
		redirecToView,
		message,
		cb,
		close,
	}: UseUpdateState<T>) {
		try {
			setLoading(true);
			const { data: addData } = await API.patch(`${url}`, formData);
			cb?.(addData as T);
			(redirectUrl || redirecToView) &&
				navigate(redirectUrl ?? `${redirecToView}/${addData?.id}`, {
					state: { detail: 'success', message },
				});
		} catch (error) {
			if (isAxiosError(error)) {
				// catchFunction(error);
			} else {
				notify({
					type: 'danger',
					msg: `Une erreur est survenue, veuillez réessayer`,
					delay: 5000,
				});
			}
		} finally {
			setLoading(false);
			if (close) {
				close();
			}
		}
	}
	async function Delete<T>({
		url,
		formData,
		redirectUrl,
		redirecToView,
		message,
		cb,
	}: UseAddUpdateState<T>) {
		try {
			setLoading(true);
			const { data: addData } = await API.delete(`${url}`);
			cb?.(addData as T);
			redirectUrl &&
				navigate(`${redirecToView}/${addData?.id}`, {
					state: { detail: 'success', message },
				});
			message &&
				notify({
					type: 'success',
					msg: message,
					delay: 5000,
				});
		} catch (error) {
			if (isAxiosError(error)) {
				// catchFunction(error);
			} else {
				notify({
					type: 'danger',
					msg: `Une erreur est survenue, veuillez réessayer`,
					delay: 5000,
				});
			}
		} finally {
			setLoading(false);
		}
	}
	return { Add, loading, Update, Delete };
};
