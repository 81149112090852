import { useCallback } from 'react';
import axios, { isAxiosError } from 'axios';
import { apiUrl } from '../env';
import { setUser, setUserError, logOut } from '../redux';
// import { API } from '../services';
import { useAppDispatch } from '../hooks';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';

const REVOKE_URL = `${apiUrl.substr(0, apiUrl.length - 10)}auth/revoke`;

export interface contryDataType {
    subdomain: string
}

export const useAuth = () => {
    const dispatch = useAppDispatch();
    const getUser = useCallback(async () => {
        try {
            const { data } = await axios.get(apiUrl + `users/authenticated-user`, {
                withCredentials: true,
                responseType: 'json',
            });
            dispatch(setUser(data));
            Cookies.set('csrfToken', data?.token, { secure: true, sameSite: 'strict' });
        } catch (err) {
            if (isAxiosError(err) && err.response) {
                dispatch(setUserError(err?.response?.data?.message));
            }
        }
    }, [dispatch]);
    // revoir le catch ????
    const countryVerification = useCallback(async (country: contryDataType) => {
        try {
            const { data } = await axios.post(apiUrl + `auth/token/complete-verification`, country, {
                withCredentials: true,
                responseType: 'json',
            });
            window.location.href = '/auth/success';
            // return <Navigate to={'/auth/success'} />
            // getUser()
        } catch (err) {
            if (isAxiosError(err) && err.response) {
                window.location.href = `/auth/failed${err?.response?.data?.message ? "?error=" + err?.response?.data?.message : ""}`;
                dispatch(setUserError(err?.response?.data?.message));
            }
        }
    }, [dispatch]);

    const logout = async () => {
        try {
            await axios.get(REVOKE_URL, {
                withCredentials: true,
            });
            dispatch(logOut());
            localStorage.clear();
            window.location.href = '/';
        } catch (e) {
            window.location.href = '/';
        }
    };

    return { getUser, logout, countryVerification };
};
