import { useAppSelector } from './reduxHooks';

export const useAcl = () => {
    const { user } = useAppSelector((state) => state.user);
    const aclActions = user?.role?.allowedActions;

    const can = (action: string[]) => {
        if (action !== undefined) {
            if (user) {
                if (user?.isAdmin) return true;

                return aclActions?.some((object) => action.includes(object.name));
            }

            return false;
        }

        return false;
    };

    const canIndex = (action: string[]) => {
        if (user?.isAdmin) return 0;
        const el = action?.find((a) => aclActions.some((acl) => acl.name === a));
        return action && el ? action.indexOf(el) : -1;
    };

    const canAll = (action: string[]) => {
        if (action !== undefined) {
            if (user) {
                if (user?.isAdmin) return true;

                const verifActions = action.map((_, key) => aclActions?.some((object) => object.name === action[key]));

                return verifActions.every((el) => el === true);
            }

            return false;
        }

        return false;
    };

    return { can, canAll, canIndex };
};
