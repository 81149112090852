import { notificationDataTypes } from '@/pages/notifications/notificationsTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface notifiationsTotalState {
    notifiationsTotal: number;
    notifiations:notificationDataTypes[];
    loading: boolean;
    error: string;
}

const initialState: Readonly<notifiationsTotalState> = {
    notifiationsTotal: 0,
    notifiations: [],
    loading: true,
    error: '',
};

const notifications = createSlice({
    name: 'notifiations',
    initialState,
    reducers: {
        setNotificationTotal: (state, action: PayloadAction<number>) => {
            state.notifiationsTotal = action.payload;
            state.loading = false;
        },
        setNotification: (state, action: PayloadAction<notificationDataTypes[]>) => {
            state.notifiations = action.payload;
            state.loading = false;
        },
        setNotificationError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export const { setNotification,setNotificationTotal, setNotificationError } = notifications.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default notifications.reducer;
