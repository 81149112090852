// @ts-nocheck
import 'vite/modulepreload-polyfill';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from './redux';
import App from './App';
import '@sobrus-com/sobrus-design-system-v2/dist/style.css';
import './App.scss';

// Conditionally load Hotjar script
if (import.meta.env.VITE_REACT_APP_ENV === 'production') {
	(function (h, o, t, j, a, r) {
		h.hj =
			h.hj ||
			function () {
				(h.hj.q = h.hj.q || []).push(arguments);
			};
		h._hjSettings = { hjid: 4967924, hjsv: 6 };
		a = o.getElementsByTagName('head')[0];
		r = o.createElement('script');
		r.async = 1;
		r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
		a.appendChild(r);
	})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<BrowserRouter>
			{/* <ConfigComponent colors={appColor}> */}
			<App />
			{/* </ConfigComponent> */}
		</BrowserRouter>
	</Provider>
	// </React.StrictMode>,
);
