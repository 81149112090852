import { apiUrl } from '@/env';
import { setNotification, setNotificationTotal, setNotificationError } from '@/redux/features/notifiationsSlice';
import { setVisualParams, setVisualParamsError } from '@/redux/features/visualParamsSlice';
import axios, { isAxiosError } from 'axios';
import { useCallback } from 'react'
import { useAppDispatch } from '.';


export const useVisualParams = () => {
  const dispatch = useAppDispatch();

  const getVisualParams = useCallback(async () => {
    try {
      const { data } = await axios.get(apiUrl + `parameters/visual-params`, {
        withCredentials: true,
        responseType: 'json',
      });

      dispatch(setVisualParams(data));
    } catch (err) {
      if (isAxiosError(err) && err.response) {
        dispatch(setVisualParamsError(err?.response?.data?.message));
      }
    }
  }, [dispatch]);

  const getNotification = useCallback(async () => {
    try {
      const { data } = await axios.get(apiUrl + `notifications/get?page=1`, {
        withCredentials: true,
        responseType: 'json',
      });

      dispatch(setNotification(data?.notifications));
      dispatch(setNotificationTotal(data?.total));
    } catch (err) {
      if (isAxiosError(err) && err.response) {
        dispatch(setNotificationError(err?.response?.data?.message));
      }
    }
  }, [dispatch]);

  return { getVisualParams, getNotification };
}
