import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/userSlice';
import tableDataReducer from './features/tableDataSlice';
import optionReducer from './features/optionSlice';
import visualParamsReducer from './features/visualParamsSlice';
import paymentParamsReducer from './features/paymentParamSlice';
import appParamsReducer from './features/appParamsSlice';
import notifiationsReducer from './features/notifiationsSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        dataTable: tableDataReducer,
        dataOptions: optionReducer,
        visualParams: visualParamsReducer,
        paymentParams: paymentParamsReducer,
        appParams: appParamsReducer,
        notifiations: notifiationsReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
