// @ts-nocheck
// Urgent , check types here !!

import { lazy, Suspense, useEffect, useLayoutEffect, useState } from 'react';
import { useAppSelector, useAuth, useTitle } from './hooks';
import { Route, Routes } from 'react-router-dom';
import { APP_ROUTES } from './data';
import { v4 as uuid } from 'uuid';
import { ProtectedRoute } from './helpers';
import { ProtectedHeader } from './Components/Header/ProtectedHeader';
import { usePaymentParams } from './hooks/usePaymentParams';
import { useAppParams } from './hooks/useAppParams';
import { useVisualParams } from './hooks/useVisualParams';
import GlovoNotificationModal from './pages/notifications/glovoNotificationModal/GlovoNotificationModal';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// glovo notifications
// const GlovoNotificationModal = lazy(() => import(/* webpackChunkName: "GlovoNotificationModal" */ './pages/notifications/glovoNotificationModal/GlovoNotificationModal'));
// glovo notifications

const Home = lazy(
	() => import(/* webpackChunkName: "home" */ './pages/LoginPage/NewLoginPage')
);
const NewLoginPage = lazy(
	() => import(/* webpackChunkName: "home" */ './pages/home')
);
const CountryVerification = lazy(
	() =>
		import(
			/* webpackChunkName: "CountryVerification" */ './pages/CountryVerification/CountryVerification'
		)
);
// const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPage" */ './pages/LoginPage'));
const SuccessComponent = lazy(
	() =>
		import(
			/* webpackChunkName: "SuccessComponent" */ './pages/SuccessComponent'
		)
);
const FailureComponent = lazy(
	() =>
		import(
			/* webpackChunkName: "FailureComponent" */ './pages/FailureComponent'
		)
);
const NotFoundPage = lazy(
	() => import(/* webpackChunkName: "NotFoundPage" */ './pages/NotFoundPage')
);
const AccessDenied = lazy(
	() => import(/* webpackChunkName: "AccessDenied" */ './pages/AccessDenied')
);

function App() {
	useTitle();
	const { user } = useAppSelector(state => state.user);

	const { getUser } = useAuth();
	const { getPaymentParams } = usePaymentParams();
	const { getVisualParams, getNotification } = useVisualParams();

	const { getAppParams } = useAppParams();

	useLayoutEffect(() => {
		getUser();
		getPaymentParams();
		getVisualParams();
		getAppParams();
		getNotification();
	}, [getUser, getPaymentParams, getAppParams]);

	const MINUTE_MS = 10000;

	useEffect(() => {
		const interval = setInterval(() => {
			if (Object.keys(user).length !== 0) {
				getNotification();
			}
		}, MINUTE_MS);

		return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
	}, [user]);

	return (
		<>
			<ProtectedHeader />
			<Routes>
				<Route
					element={
						<Suspense fallback={null}>
							<CountryVerification />
						</Suspense>
					}
					path="/auth/country-verification"
				/>

				<Route
					element={
						<Suspense fallback={null}>
							<NewLoginPage />
						</Suspense>
					}
					path="/login"
				/>

				<Route
					element={
						<Suspense fallback={null}>
							<Home />
						</Suspense>
					}
					path="/"
				/>

				<Route
					element={
						<Suspense fallback={null}>
							<NotFoundPage />
						</Suspense>
					}
					// path="/error"
					path="*"
				/>

				<Route
					path="/denied_access"
					element={
						<Suspense fallback={null}>
							<AccessDenied />
						</Suspense>
					}
				/>

				<Route
					path="/auth/success"
					element={
						<Suspense fallback={null}>
							<SuccessComponent />
						</Suspense>
					}
				/>

				<Route
					path="/auth/failed"
					element={
						<Suspense fallback={null}>
							<FailureComponent />
						</Suspense>
					}
				/>

				{APP_ROUTES.map(({ path, acl, component: Component }) => (
					<Route
						key={uuid()}
						element={<ProtectedRoute acl={acl} element={Component} />}
						path={path}
					/>
				))}
			</Routes>
			{/* <Suspense fallback={null}> */}
			<GlovoNotificationModal />
			{/* </Suspense> */}
		</>
	);
}

export default App;
