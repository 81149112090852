import {
    MegaNavBar,
    MegaNavBarLogo,
    MegaNavBarItems,
    MegaNavBarItem,
    MegaNavBarItemLink,
    MegaNavUser,
    MegaNavUserProfile,
    MegaNavUserLogout,
    MegaNavBarItemSubMenu,
    MegaNavBarItemSubMenuItem,
    MegaNavBarItemSubMenuItemLink,
    LazyLoadImage,
} from '@sobrus-com/sobrus-design-system-v2';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Logo from '../../assets/Ecopara_logo.png';
import { useAcl, useAppSelector, useAuth } from '../../hooks';
import { navPathData } from '../../data';
import { IoNotificationsOutline } from 'react-icons/io5';
import './Header.scss';

function Header() {
    const { can, canIndex } = useAcl();
    const { user } = useAppSelector((state) => state.user);
    const { notifiationsTotal } = useAppSelector((state) => state.notifiations);
    const { logout } = useAuth();
    const location = useLocation();

    return (
        <MegaNavBar className="header" size='small'>
            <MegaNavBarLogo>
                <Link to='/admin/home'>
                    <LazyLoadImage alt='sobrus pharma' src={Logo} width={120} />
                </Link>
            </MegaNavBarLogo>
            <MegaNavBarItems>
                <MegaNavBarItem className={notifiationsTotal !== 0 ? 'notification-nav-item-active active' : ''}>
                    <NavLink to="/admin/notifications">
                        <div className="notification-nav-item">
                            <p>{notifiationsTotal}</p>
                            <IoNotificationsOutline size={25} color="#274052" />
                        </div>
                    </NavLink>
                </MegaNavBarItem>
                {navPathData.map((el) => can(el?.does) && (
                    <MegaNavBarItem key={el?.itemTitle}>
                        <MegaNavBarItemLink Tag={NavLink} title={`${el.itemTitle}`}
                            to={typeof el.to === 'string' ? el?.to : el?.to[canIndex(el?.does)]}
                            className={el?.subItems?.some((item) => item?.to === location?.pathname || (item?.to && location?.pathname && location?.pathname.includes(item?.to))) ? 'active' : ''}>
                            {el.itemTitle}
                        </MegaNavBarItemLink>
                        {el?.subItems?.length !== 0 && el?.subItems && (
                            <MegaNavBarItemSubMenu>
                                {el?.subItems.map((sub) => can(sub?.does) && (
                                    <MegaNavBarItemSubMenuItem key={sub?.to}>
                                        <MegaNavBarItemSubMenuItemLink Tag={NavLink} title={`${sub?.itemTitle}`} to={typeof sub.to === 'string' ? sub?.to : sub?.to[canIndex(sub?.does)]}>
                                            {sub?.itemTitle}
                                        </MegaNavBarItemSubMenuItemLink>
                                    </MegaNavBarItemSubMenuItem>
                                )
                                )}
                            </MegaNavBarItemSubMenu>
                        )}
                    </MegaNavBarItem>
                )
                )}
            </MegaNavBarItems>
            <MegaNavUser fullName={`${user.firstName} ${user.lastName}`}>
                {/* <MegaNavUserProfile label={'Profil'} onClick={()=>{}} /> */}
                <MegaNavUserLogout onClick={() => logout()} />
            </MegaNavUser>
        </MegaNavBar>
    );
}

export default Header;
