export const ACL = {
	HOME: {
		GET_ALL: [
			'get_orders_status_key_figures',
			'get_top_sold_categories',
			'get_performance_key_figures',
		],
		GET_TOP_SOLDE: ['get_top_sold_categories'],
		GET_PERFORMANCE_KEY_FIGURES: ['get_performance_key_figures'],
		GET_ORDER_STATUS_KEY_FIGURES: ['get_orders_status_key_figures'],
	},
	CLIENTS: {
		GET_ALL: ['get_list_of_all_clients_with_pagination_and_filters'],
		GET_BY_ID: ['get_one_client_by_its_id'],
		AUTHORISE: ['toggle_client_authorization_for_professional_mode'],
	},
	NOTIFICATIONS: {
		GET_ALL: [
			'get_list_of_all_unseen_notifications_with_pagination_and_filters',
		],
		GET_ALL_HISTORY: [
			'get_list_of_all_notifications_history_with_pagination_and_filters',
		],
		UPDATE_ONE: ['get_one_post_by_its_id', 'edit_a_post'],
		UPDATE_ALL: ['get_one_post_by_its_id', 'edit_a_post'],
	},
	CART: {
		GET_ALL: ['get_list_of_all_carts_with_pagination_and_filters'],
		GET_BY_ID: ['get_one_cart_by_its_id'],
	},
	ORDER: {
		GET_ALL: ['get_list_of_all_orders_with_pagination_and_filters'],
		GET_BY_ID: ['get_one_order_by_its_id'],
		GET_ALL_PRODUCTS: ['get_one_order_list_of_products'],
		GET_STATUS_HISTORY: ['get_one_order_status_history'],
		UPDATE_PAYMENT_STATUS: ['change_one_order_payment_status'],
		ASSIGN_ORDER_TO_CARRIER: ['assign_one_order_to_carrier'],
		UPDATE_DELIVERY_STATUS: ['change_one_order_delivery_status'],
		UPDATE_ALL_STATUS: [
			'change_one_order_delivery_status',
			'assign_one_order_to_carrier',
			'change_one_order_payment_status',
		],
	},
	PRODUCTS: {
		GET_ALL: ['get_list_of_all_products_with_pagination_and_filters'],
		GET_BY_ID: ['get_one_product_by_its_id'],
		UPDATE: ['get_one_product_by_its_id', 'edit_a_product_data'],
		GLOVO_LIST: ['get_list_of_all_products_not_processed_yet_in_glovo'],
	},
	ADS: {
		GET_ALL: ['get_list_of_all_posts_with_pagination_and_filters'],
		GET_BY_ID: ['get_one_post_by_its_id'],
		CREATE: ['create_a_new_post'],
		UPDATE: ['get_one_post_by_its_id', 'edit_a_post'],
		ARCHIVE: ['archive_a_post'],
	},
	FLASHINFO: {
		GET_ALL: ['get_list_of_all_flash_infos_with_pagination_and_filters'],
		GET_BY_ID: ['get_one_flash_info_by_its_id'],
		CREATE: ['create_a_new_flash_info'],
		UPDATE: ['get_one_flash_info_by_its_id', 'edit_a_flash_info'],
	},
	CATEGORIES: {
		GET_ALL_CATEGORIES: [
			'get_list_of_all_categories_with_pagination_and_filters',
		],
		GET_ALL_SUB_CATEGORIES: [
			'get_list_of_all_sub_categories_with_pagination_and_filters',
		],
		GET_ALL_SUB_SUB_CATEGORIES: [
			'get_list_of_all_sub_sub_categories_with_pagination_and_filters',
		],
		GET_BY_ID: ['get_category_or_sub_category_or_sub_sub_category_by_its_id'],
		CREATE: ['create_a_new_category_or_sub_category_or_sub_sub_category'],
		UPDATE: ['edit_a_category_or_sub_category_or_sub_sub_category'],
		ARCHIVE: ['archive_a_category_or_sub_category_or_sub_sub_category'],
	},
	PROMOCODE: {
		GET_ALL: ['get_list_of_all_promo_codes_with_pagination_and_filters'],
		GET_BY_ID: ['get_one_promo_code_by_its_id'],
		CREATE: ['create_a_new_promo_code'],
		UPDATE: ['get_one_promo_code_by_its_id', 'edit_a_promo_code'],
	},
	CLIENT_REVIEW: {
		GET_ALL: ['get_list_of_all_orders_reviews_with_pagination_and_filters'],
		GET_BY_ID: ['get_one_orders_review_by_its_id'],
	},
	REPORTS: {
		CARRIERS: ['get_orders_per_carriers_report'],
		TURNOVER: ['get_turnover_report'],
		ORDERS: ['get_orders_report'],
	},
	WALLET: {
		GET_ALL: ['get_list_of_my_online_payments'],
		GET_BY_ID: ['get_one_online_payment_by_id'],
		UPDATE: ['get_one_online_payment_by_id', 'change_online_payment_status'],
	},
	BLOG_ARTICLES: {
		GET_ALL: ['get_list_of_all_blog_articles_with_pagination_and_filters'],
		GET_BY_ID: ['get_one_blog_article_by_its_id'],
		CREATE: ['create_a_new_blog_article'],
		UPDATE: ['get_one_blog_article_by_its_id', 'edit_a_blog_article'],
	},
	PARAMS: {
		APP_PARAMS_GET: ['get_my_application_params'],
		APP_PARAMS_UPDATE: [
			'get_my_application_params',
			'edit_my_application_params',
		],
		APP_VISUAL_PARAMS_GET: ['get_my_visual_params'],
		APP_VISUAL_PARAMS_UPDATE: ['get_my_visual_params', 'edit_my_visual_params'],
		APP_PAYEMENT_PARAMS_GET: ['get_my_payment_params'],
		APP_PAYEMENT_PARAMS_UPDATE: [
			'get_my_payment_params',
			'edit_my_payment_params',
		],
		ADVANTEGES: {
			GET_ALL: ['get_list_of_all_advantages_with_pagination_and_filters'],
			CREATE: ['create_a_new_advantage'],
			UPDATE: ['edit_an_advantage'],
			DELETE: ['toggle_an_advantage_status'],
		},
		PRODUCT_LINE: {
			GET_ALL: ['get_list_of_all_product_lines_with_pagination_and_filter'],
			UPDATE: ['edit_my_application_params'],
		},
		EMAIL_TEMPLATE: {
			GET_ALL: ['get_marketing_emails_templates_list'],
			GET_BY_ID: ['get_marketing_email_template_by_its_id'],
			UPDATE: [
				'get_marketing_email_template_by_its_id',
				'edit_a_marketing_email_template',
			],
		},
		SHIPPING_COST: {
			GET_ALL: ['get_list_of_all_shipping_costs_with_pagination_and_filters'],
			GET_BY_ID: ['get_one_shipping_cost_by_its_id'],
			UPDATE: ['edit_a_shipping_cost'],
			CREATE: ['create_a_new_shipping_cost'],
			DELETE: ['archive_shipping_cost'],
		},
		CARRIERS: {
			GET_ALL: ['get_list_of_all_carriers_with_pagination_and_filters'],
			UPDATE: ['toggle_carrier_status'],
		},
		ACTIVETE_PHARMA_STOCK: ['activate_used_products_in_pharma'],
		ACLS_ROLES: {
			GET_ALL: ['get_list_of_all_roles_with_pagination_and_filters'],
			GET_BY_ID: ['get_one_role_by_its_id'],
			UPDATE: ['edit_a_role'],
			CREATE: ['create_a_new_role'],
		},
		ACLS_USERS: {
			GET_ALL: ['get_list_of_all_users_with_pagination_and_filters'],
			UPDATE: ['assign_role_to_user'],
		},
	},
};
