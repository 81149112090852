import { AppParamsType } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface appParamsState {
    appParams?: AppParamsType;
    loading: boolean;
    error: string;
}

const initialState: Readonly<appParamsState> = {
  appParams: {} as AppParamsType,
    loading: true,
    error: '',
};

const appParams = createSlice({
    name: 'appParams',
    initialState,
    reducers: {
        setAppParams: (state, action: PayloadAction<AppParamsType>) => {
            state.appParams = action.payload;
            state.loading = false;
        },
        setAppParamsError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});

export const { setAppParams, setAppParamsError } = appParams.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default appParams.reducer;
